export class PopinManager {
    /**
     * @param {Popin[]} popins
     */
    constructor(popins) {
        this._popins = popins;

        this._popins.forEach(popin => {
            popin.on('open', this._onPopinOpen.bind(this));
            popin.on('close', this._onPopinClose.bind(this));
        });

        document.querySelectorAll('[data-popin]').forEach(element => {
            element.addEventListener('click', event => this._findAndOpenPopin(event.target.dataset.popin));
        });
    }

    _findAndOpenPopin(id) {
        const popin = this._popins.find(popin => popin.id() === id);
        if (!popin) {
            console.warn(`Popin ${id} not found`);
            return;
        }
        popin.toggle();
    }

    _onPopinOpen() {
        document.body.style.overflow = 'hidden';
    }

    _onPopinClose() {
        if (this._popins.every(popin => !popin.isVisible())) {
            document.body.style.overflow = '';
        }
    }
}
